import React from "react"

import styles from "./index.module.scss"

export default () => (
  <>
    <div className={`${styles.header}`}>
      <h2>Technical Official Introductory</h2>
      <p>The easiest way to learn web design, HTML5 and CSS3.</p>

      <a href="/courses/toi/content">
        <i className="fas fa-shopping-cart" />
        Buy now
      </a>
    </div>

    <ul className={`${styles.container}`}>
      <li>
        <h3 className={`${styles.containerHeader}`}> About this Course</h3>

        <p>
          {" "}
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
          nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
          volutpat. Ut wisi enim ad minim laoreet dolore magna aliquam erat
          volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
          ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat
        </p>

        <p>
          {" "}
          Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper
          suscipit lobortis nisl ut aliquip ex ea commodo consequa mod tincidunt
          ut laoreet dolore magna aliquam erat volutpat.{" "}
        </p>

        <p>
          Magna consectetuer adipiscing elit, sed diam nonummy nibh euismod
          tincidunt ut laoreet dolore aliquam erat volutpat. Ut wisi enim ad
          minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis
          nisl ut aliquip ex ea commodo consequat. Nam liber tempor cum soluta
          nobis eleifend option congue nihil imperdiet doming id quod mazim
          placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer
          adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
          dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
          nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
          ea commodo consequat{" "}
        </p>
      </li>
    </ul>
  </>
)
